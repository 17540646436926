export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      'ZoomMeetingNode',
      'CompanyNode',
      'BaseCompanyNode',
      'UserNode',
      'GroupNode',
      'PermissionNode',
      'CourseNode',
      'SiteNode',
      'FaviconGeneratorNode',
      'MosaicoNode',
      'ImageNode',
      'SurveyNode',
      'AssessmentNode',
      'AssessmentSessionNode',
      'CompanyUserInvitationNode',
      'QuestionNode',
      'AnswerNode',
      'SurveyResponseNode',
      'CourseParticipationNode',
      'CourseInvitationNode',
      'CertificateNode',
      'CertificateTemplateNode',
      'CourseCertificateTemplateMapNode',
      'CourseModuleCertificateTemplateMapNode',
      'CourseModuleNode',
      'AuthorNode',
      'AuthorsGroupNode',
      'ReminderNode',
      'TaskResultNode',
      'SurveyModuleNode',
      'CourseModuleAssessmentVideoNode',
      'CourseModuleAssessmentNode',
      'CourseModuleAssessmentSurveyNode',
      'CourseModuleAssessmentExternalWebinarNode',
      'VideowallModuleNode',
      'ExternalWebinarModuleNode',
      'ChatModuleNode',
      'VideoModuleNode',
      'LayoutModuleNode',
      'DocumentModuleNode',
      'IframeModuleNode',
      'DbtemplatesNode',
      'TagType',
      'CourseCategoryNode',
      'ThemeNode',
      'CourseParticipationImportFileNode',
      'CourseUserParticipationQuotasNode',
      'AvatarNode',
      'FileNode',
      'ProfileNode',
      'AddressNode',
      'PreferencesNode',
      'PresenceNode',
      'RoomNode',
      'WebLinkNode',
      'CompanyThemeCustomizationNode',
      'StreamServiceNode',
      'NoteNode',
      'PublicCompanyNode',
    ],
    QuestionUnion: ['QuestionNode'],
    SurveyUnion: ['SurveyNode'],
    CourseModuleUnion: [
      'SurveyModuleNode',
      'ExternalWebinarModuleNode',
      'CourseModuleNode',
      'ChatModuleNode',
      'VideoModuleNode',
      'LayoutModuleNode',
      'DocumentModuleNode',
      'IframeModuleNode',
      'VideowallModuleNode',
    ],
    CourseModuleAssessmentUnion: [
      'CourseModuleAssessmentVideoNode',
      'CourseModuleAssessmentExternalWebinarNode',
      'CourseModuleAssessmentSurveyNode',
      'CourseModuleAssessmentNode',
    ],
  },
};
export default result;
